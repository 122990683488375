import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage  } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAczSXdf6azNHhXD-W-qMngu0T3TY8vTcA",
  authDomain: "listalot-440122.firebaseapp.com",
  projectId: "listalot-440122",
  storageBucket: "listalot-440122.firebasestorage.app",
  messagingSenderId: "225652761371",
  appId: "1:225652761371:web:3dcfb8380554e050b6e9be",
  measurementId: "G-F0B99D615E"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);
export const db = getFirestore(app); 
export { auth, storage, firestore, app as default };
