import React, { useState, useEffect } from 'react';
import axios from 'axios';
const BarcodeLookup = () => {
  const [barcode, setBarcode] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleBarcodeChange = (e) => {
    setBarcode(e.target.value);
  };

  const handleLookup = async () => {
    if (!barcode) return alert("Please enter a barcode");

    setLoading(true);
    try {
      const response = await axios.get("https://backend.listornot.com/lookup-barcode", {
        params: { barcode },
      });
      setResult(response.data.results);
    } catch (error) {
      console.error("Error looking up barcode:", error);
      alert("Failed to look up barcode");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 md:p-8 bg-white rounded-lg shadow-lg space-y-4 max-w-md mx-auto">
      <h2 className="text-lg md:text-xl font-semibold text-gray-700">
        Barcode Lookup
      </h2>
      <input
        type="text"
        placeholder="Enter Barcode"
        value={barcode}
        onChange={handleBarcodeChange}
        className="p-2 border rounded w-full"
      />
      <button
        onClick={handleLookup}
        disabled={loading}
        className={`px-4 py-2 ${
          loading ? "bg-gray-500" : "bg-purple-500"
        } text-white rounded hover:bg-purple-600 transition disabled:opacity-50`}
      >
        {loading ? "Looking up..." : "Lookup Barcode"}
      </button>

      {result && (
        <div className="mt-6 w-full">
          <h3 className="text-lg font-medium text-gray-700">Lookup Results</h3>
          {result.map((item, index) => (
            <div
              key={index}
              className="p-4 mt-4 bg-gray-100 rounded-lg shadow-md"
            >
              <h4 className="text-md font-semibold">{item.title}</h4>
              <p>
                <strong>Category:</strong> {item.category}
              </p>
              <p>
                <strong>Brand:</strong> {item.brand || "Unknown"}
              </p>
              <p>
                <strong>Size:</strong> {item.size || "N/A"}
              </p>
              <p>
                <strong>Description:</strong> {item.description}
              </p>
              {item.stores && (
                <div>
                  <h5 className="mt-4 font-semibold">Available at:</h5>
                  <ul className="list-disc list-inside">
                    {item.stores.map((store, i) => (
                      <li key={i}>
                        {store.name} - {store.price} {store.currency_symbol}
                        <a
                          href={store.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-2 text-blue-600 underline"
                        >
                          View
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BarcodeLookup;