import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Skeleton } from "@mui/material";
import Webcam from "react-webcam";

const CameraModal = ({   open,
  onClose,
  onCapture,
  title,
  setImages,
  setOriginalPreviews,
  targetlotIndex,
  lots,
  setlots,
  setlotDescriptions,
  setImageOptions,}) => {
  const webcamRef = React.useRef(null);
  const [capturedPreviews, setCapturedPreviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!open) {
      setCapturedPreviews([]);
      setLoading(true); // Reset loading state when the modal is closed
    } else {
      // Simulate a loading delay when the modal opens
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot(); // Get the captured image as base64
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob()) // Convert base64 to Blob
        .then((blob) => {
          const file = new File([blob], `captured_image_${Date.now()}.jpg`, {
            type: "image/jpeg",
          }); // Create a File object
          const preview = URL.createObjectURL(blob); // Generate a preview URL
          setImages((prev) => [...prev, file]); // Add to images state
          setOriginalPreviews((prev) => [...prev, preview]); // Add to previews state
  
          if (targetlotIndex !== null) {
            // Add to a specific lot
            const updatedlots = [...lots];
            updatedlots[targetlotIndex] = [
              ...updatedlots[targetlotIndex],
              file,
            ];
            setlots(updatedlots);
          } else {
            // Create a new lot with the captured image
            setlots((prevlots) => [...prevlots, [file]]);
            setlotDescriptions((prevDescriptions) => [...prevDescriptions, ""]);
            setImageOptions((prevOptions) => [
              ...prevOptions,
              [{ option: "original" }],
            ]);
          }
          setCapturedPreviews((prev) => [...prev, preview]); // Store the preview for display
        })
        .catch((error) => console.error("Error processing capture:", error));
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Switch to column for mobile
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
          overflowY: "auto", // Allow scrolling for smaller devices
        }}
      >
        {/* Camera Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRight: { xs: "none", md: "1px solid #ddd" },
            borderBottom: { xs: "1px solid #ddd", md: "none" },
            p: 2,
          }}
        >
          <h3 className="text-lg font-semibold text-center">{title}</h3>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              sx={{ borderRadius: "8px" }}
            />
          ) : (
            <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 1280, // Desired width
              height: 720, // Desired height
              facingMode: "environment", // Use the environment (rear) camera
            }}
            className="w-full h-auto rounded-md shadow-md"
          />
          )}
          <div className="flex justify-end space-x-4 mt-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCapture}
              disabled={loading}
            >
              Capture
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Done
            </Button>
          </div>
        </Box>

        {/* Captured Previews Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            p: 2,
            overflowY: "auto",
          }}
        >
          <h3 className="text-lg font-semibold mb-4 text-center">Captured Images</h3>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={150}
                sx={{ mb: 2, borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={150}
                sx={{ mb: 2, borderRadius: "8px" }}
              />
            </>
          ) : (
            <div
              className="grid grid-cols-2 gap-4 w-full"
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {capturedPreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Captured ${index}`}
                  className="w-full h-auto rounded-md shadow-md"
                />
              ))}
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CameraModal;