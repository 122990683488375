import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import MergeIcon from "@mui/icons-material/Merge";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const LotDisplay = ({
  lots,
  lot,
  lotIndex,
  lotRef,
  lotDescriptions,
  imageOptions,
  handleOpenCamera,
  setlotDescriptions,
  keepOriginalForAll,
  keepProcessedForAll,
  handleDescriptionChange,
  handleOptionChange,
  handleRemoveImage,
  handlelotChange,
  addImageTolot,
  deletelot,
  splitlot,
  mergelotIndex,
  setMergelotIndex,
  targetlotIndex,
  setTargetlotIndex,
  mergelots,
  setIsCameraOpen,
}) => {
  const [showlot, setShowlot] = useState(false);

  // Add a delay to display the lot content
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowlot(true);
    }, 100); // 100ms delay
    return () => clearTimeout(timer);
  }, []);

  if (!showlot) {
    // Skeleton loader for the lot content
    return (
      <div className="p-4 mt-4 bg-gray-100 rounded-md border">
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", marginBottom: "8px" }}
          width="40%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "0.875rem", marginBottom: "16px" }}
          width="60%"
        />
        <Skeleton variant="rectangular" width="100%" height={150} />
      </div>
    );
  }

  return (
    <div
  key={lotIndex}
  ref={lotRef}
  className="border p-4 rounded-md mt-4 bg-gray-100"
>
  {/* lot Header */}
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
    <h3 className="text-md font-semibold">lot {lotIndex + 1}</h3>
    <div className="flex flex-wrap sm:flex-nowrap items-center gap-2">
      {/* Add File */}
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={(e) => {
          const files = Array.from(e.target.files);
          files.forEach((file) => addImageTolot(lotIndex, file));
        }}
        className="hidden"
        id={`add-file-${lotIndex}`}
      />
      <label htmlFor={`add-file-${lotIndex}`}>
        <AttachFileIcon titleAccess="Add File" className="cursor-pointer" />
      </label>

      {/* Add Folder */}
      <input
        type="file"
        accept="image/*"
        multiple
        webkitdirectory=""
        onChange={(e) => {
          const files = Array.from(e.target.files);
          files.forEach((file) => addImageTolot(lotIndex, file));
        }}
        className="hidden"
        id={`add-folder-${lotIndex}`}
      />
      <label htmlFor={`add-folder-${lotIndex}`}>
        <CreateNewFolderIcon
          titleAccess="Add Folder"
          className="cursor-pointer"
        />
      </label>

      {/* Open Camera */}
      <button onClick={handleOpenCamera}>
        <AddAPhotoIcon titleAccess="Open Camera" />
      </button>

      {/* Merge lot */}
      <button onClick={() => setMergelotIndex(lotIndex)}>
        <MergeIcon />
      </button>

      {/* Delete lot */}
      <button onClick={() => deletelot(lotIndex)}>
        <DeleteIcon />
      </button>
    </div>
  </div>

  {/* Merge lot Section */}
  {mergelotIndex === lotIndex && (
    <div className="mt-4 flex flex-col sm:flex-row sm:space-x-2 items-start sm:items-center space-y-2 sm:space-y-0 py-2">
      <label
        htmlFor={`target-lot-${lotIndex}`}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        Select a lot to merge with:
      </label>
      <select
        id={`target-lot-${lotIndex}`}
        value={targetlotIndex !== null ? targetlotIndex : ""}
        onChange={(e) => setTargetlotIndex(Number(e.target.value))}
        className="border p-2 rounded w-full sm:w-[40%]"
      >
        <option value="" disabled>
          Select lot
        </option>
        {lots.map(
          (_, idx) =>
            idx !== lotIndex && (
              <option key={idx} value={idx}>
                lot {idx + 1}
              </option>
            )
        )}
      </select>
      {targetlotIndex !== null && (
        <button
          onClick={() => {
            if (targetlotIndex !== null)
              mergelots(lotIndex, targetlotIndex);
          }}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
        >
          Confirm Merge
        </button>
      )}
    </div>
  )}

  {/* Description */}
  <textarea
    value={lotDescriptions[lotIndex]}
    onChange={(e) => handleDescriptionChange(lotIndex, e.target.value)}
    placeholder="lot Description"
    className="w-full mt-2 p-2 border rounded"
  />

  {/* Action Buttons */}
  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full py-2 px-5">
    <button
      onClick={keepOriginalForAll}
      className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 w-full sm:w-[50%]"
    >
      Keep Original for All
    </button>
    <button
      onClick={keepProcessedForAll}
      className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 w-full sm:w-[50%]"
    >
      Keep Processed for All
    </button>
  </div>

  {/* Image Grid */}
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
    {lot.map((image, imageIndex) => (
      <div
        key={imageIndex}
        className="relative flex flex-col items-center bg-white p-2 rounded shadow"
      >
        <img
          src={URL.createObjectURL(image)}
          alt={`Original ${imageIndex}`}
          className="w-full max-w-[150px] sm:w-40 h-auto rounded-md shadow-md"
        />
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-3">
          <button
            onClick={() =>
              handleOptionChange(
                lotIndex,
                imageIndex,
                imageOptions[lotIndex][imageIndex]?.option === "original"
                  ? "processed"
                  : "original"
              )
            }
            className={`mt-2 px-4 py-2 rounded ${
              imageOptions[lotIndex][imageIndex]?.option === "original"
                ? "bg-blue-500 text-white"
                : "bg-gray-500 text-white"
            }`}
          >
            {imageOptions[lotIndex][imageIndex]?.option === "original"
              ? "Background On"
              : "Background Off"}
          </button>
          <button
            onClick={() => splitlot(lotIndex, imageIndex + 1)}
            className="mt-2 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
          >
            Split Here
          </button>
          <button
            onClick={() => handleRemoveImage(lotIndex, imageIndex)}
            className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
          >
            Remove
          </button>
        </div>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 py-2 w-full">
          <label
            htmlFor={`move-lot-${lotIndex}-${imageIndex}`}
            className="block"
          >
            Move to lot:
          </label>
          <select
            id={`move-lot-${lotIndex}-${imageIndex}`}
            value={lotIndex}
            onChange={(e) =>
              handlelotChange(
                lotIndex,
                imageIndex,
                Number(e.target.value)
              )
            }
            className="p-1 border rounded"
          >
            {lots.map((_, index) => (
              <option key={index} value={index}>
                lot {index + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
    ))}
  </div>
</div>
  );
};

export default LotDisplay;
