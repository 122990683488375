import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Button, IconButton, Modal, Box, TextField, LinearProgress } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import ResultsDisplay from "./ResultsDisplay";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PreviousAnalysesTable = ({ previousAnalyses }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [isLoadingResults, setIsLoadingResults] = useState(false); // Loading state for results
  const [lotNumber, setLotNumber] = useState(0); // Starting lot number
  const handleViewDetails = async (analysis) => {
    setIsLoadingResults(true);
    setOpenModal(true);

    try {
      // Fetch analysis details if results are not already available
      if (!analysis.results) {
        const response = await axios.get(
          `https://backend.listornot.com/get-analysis-results?pod=${analysis.pod}`
        );
        const fetchedResults = response.data.results;
        setCurrentDetails({
          ...analysis,
          results: fetchedResults,
        });
      } else {
        setCurrentDetails(analysis);
      }
    } catch (error) {
      console.error("Error fetching results:", error.message);
    } finally {
      setIsLoadingResults(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentDetails(null);
  };

  const handleDownloadJSON = (results) => {
    const resultsWithLot = results.map((result, index) => ({
      lotNumber: lotNumber + index,
      ...result,
    }));

    const jsonBlob = new Blob([JSON.stringify(resultsWithLot, null, 2)], {
      type: "application/json",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(jsonBlob);
    link.download = "pod-results.json";
    link.click();
  };

  const handleDownloadCSV = (results) => {
    if (!results || !Array.isArray(results)) {
      alert("No valid results to download as CSV.");
      return;
    }

    const resultsWithLot = results.map((result, index) => ({
      lotNumber: lotNumber + index,
      ...result,
    }));

    const csvHeaders = Object.keys(resultsWithLot[0]).join(","); // Get headers
    const csvRows = resultsWithLot.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`) // Escape values for CSV
        .join(",")
    );
    const csvContent = [csvHeaders, ...csvRows].join("\n");

    const csvBlob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(csvBlob);
    link.download = "pod-results.csv";
    link.click();
  };

  const handleDownloadImagesAsZip = async (results) => {
    const zip = new JSZip();
    const folder = zip.folder("images"); // Main folder for all images

    for (const [index, result] of results.entries()) {
      const currentLotNumber = lotNumber + index; // Current lot number
      let imageIndex = 1; // Reset the image index for each lot

      const maxImages = Math.max(
        result.original?.length || 0,
        result.no_background?.length || 0
      );

      for (let i = 0; i < maxImages; i++) {
        // Add original image if it exists
        if (result.original && result.original[i]) {
          const originalImageBlob = await fetch(result.original[i]).then((res) =>
            res.blob()
          );
          folder.file(
            `${currentLotNumber}-${imageIndex}-original.png`,
            originalImageBlob
          );
        }

        // Add no-background image if it exists
        if (result.no_background && result.no_background[i]) {
          const noBgImageBlob = await fetch(result.no_background[i]).then((res) =>
            res.blob()
          );
          folder.file(
            `${currentLotNumber}-${imageIndex}-no-bg.png`,
            noBgImageBlob
          );
        }

        // Increment image index for the next pair
        imageIndex++;
      }
    }

    // Generate ZIP and trigger download
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "images.zip");
  };

  return (
    <div className="mt-4">
      {/* Lot Number Input */}
      <Box mb={4}>
        <TextField
          label="Set Starting Lot Number"
          type="number"
          value={lotNumber}
          onChange={(e) => setLotNumber(parseInt(e.target.value, 10) || 0)}
          variant="outlined"
          size="small"
        />
      </Box>

      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="border border-gray-300 px-2 py-2">Pod</th>
            <th className="border border-gray-300 px-2 py-2">JSON</th>
            <th className="border border-gray-300 px-2 py-2">CSV</th>
            <th className="border border-gray-300 px-2 py-2">Images (ZIP)</th>
            <th className="border border-gray-300 px-2 py-2">Preview</th>
          </tr>
        </thead>
        <tbody>
          {previousAnalyses.map((analysis, index) => (
            <tr key={index} className="bg-gray-100">
              <td className="border border-gray-300 px-2 py-2">
                {analysis.pod}
              </td>
              {analysis.results && analysis.results?.length >0 ? (
                <>
                  <td className="border border-gray-300 px-2 py-2 text-center">
                    <IconButton
                      color="primary"
                      onClick={() => handleDownloadJSON(analysis.results || [])}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-center">
                    <IconButton
                      color="success"
                      onClick={() => handleDownloadCSV(analysis.results || [])}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-center">
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        handleDownloadImagesAsZip(analysis.results || [])
                      }
                    >
                      <FolderZipIcon />
                    </IconButton>
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleViewDetails(analysis)}
                    >
                      View
                    </Button>
                  </td>
                </>
              ) : (
                <>
                 <td
            className="border border-gray-300 px-2 py-2 text-center"
            colSpan={4}
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for viewing details */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <div className="w-full flex justify-between">
            <h2 className="text-lg font-semibold">Pod Details</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
              className="mt-4"
            >
              Close
            </Button>
          </div>

          {isLoadingResults ? (
            <div className="mt-4">
              <p className="text-gray-600">Loading results, please wait...</p>
              <LinearProgress />
            </div>
          ) : currentDetails && currentDetails.results ? (
            <div className="mt-4">
              <h3 className="font-bold">Pod #{currentDetails.pod}</h3>
              <p>Date: {new Date(currentDetails.timestamp).toLocaleString()}</p>
              <ResultsDisplay results={currentDetails.results} />
            </div>
          ) : (
            <p className="mt-4 text-gray-600">
              No results available for this pod.
            </p>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PreviousAnalysesTable;